import React from 'react';
import {
  EmailIcon,
  EmailShareButton,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share';
import { Box, styled } from '@mui/material';
import PropTypes from 'prop-types';

import { sxPropType } from '../proptypes/misc';

const buttonStyles = ({ theme }) => ({
  '& svg > path': {
    fill: `${theme.palette.primary.light} !important`,
  },
  outline: 'none',
  '&:hover, &:focus': {
    '& svg > path': {
      fill: `${theme.palette.secondary.main} !important`,
    },
  },
});

const StyledTwitterShareButton = styled(TwitterShareButton)(buttonStyles);
const StyledFacebookShareButton = styled(FacebookShareButton)(buttonStyles);
const StyledEmailShareButton = styled(EmailShareButton)(buttonStyles);

function ShareLinks({ url, sx }) {
  return (
    <Box component="span" sx={sx}>
      <StyledTwitterShareButton url={url.href}>
        <TwitterIcon size={40} bgStyle={{ fill: 'transparent' }} />
      </StyledTwitterShareButton>
      <StyledFacebookShareButton url={url.href}>
        <FacebookIcon size={40} bgStyle={{ fill: 'transparent' }} />
      </StyledFacebookShareButton>
      <StyledEmailShareButton url={url.href}>
        <EmailIcon size={40} bgStyle={{ fill: 'transparent' }} />
      </StyledEmailShareButton>
    </Box>
  );
}

ShareLinks.propTypes = {
  sx: sxPropType,
  // eslint-disable-next-line react/forbid-prop-types
  url: PropTypes.object.isRequired,
};

ShareLinks.defaultProps = {
  sx: [],
};

export default ShareLinks;
