import React from 'react';
import { graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-react-intl';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import localeDateString from '../utils/localeDateString';

import { Markdown, Container, Button, Typography, GatsbyImage } from '../components/theme';

import Layout from '../components/layout/Layout';
import Newsletter from '../components/Newsletter';
import ReadingTime from '../components/ReadingTime';
import Seo from '../components/Seo';
import ShareLinks from '../components/ShareLinks';

function BlogEntry({ data, location }) {
  const {
    blogAuthor,
    blogContent,
    blogDate,
    blogDesc,
    blogImage,
    blogImageAlt,
    blogImageCred,
    blogSubtitle,
    blogTitle,
  } = data.blog;

  const intl = useIntl();
  const baseCategory = '/blog/';
  const formattedBlogDate = localeDateString(blogDate);

  const coverImage = {
    image: blogImage,
    alt: blogImageAlt || '',
    credits: blogImageCred || '',
  };

  return (
    <Layout lightTheme baseCategory={baseCategory}>
      <Container sx={{ marginTop: '80px' }} narrow>
        <Typography gutterBottom variant="h1">
          <Typography sx={{ display: 'block' }} variant="overline">
            {blogSubtitle}
          </Typography>
          {blogTitle}
        </Typography>
        <Typography paragraph variant="body1">
          {blogDesc}
        </Typography>
        <Typography
          component="div"
          sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '40px' }}
          mb={9}
          variant="caption"
        >
          <span>
            {formattedBlogDate}&nbsp;&nbsp;·&nbsp;&nbsp;
            <ReadingTime text={blogContent.data.Text} />
          </span>
          <ShareLinks sx={{ marginTop: '-9px' }} url={location} />
        </Typography>
        <Box sx={(theme) => ({ marginBottom: '42px', color: theme.palette.primary.light })}>
          {blogImage && (
            <GatsbyImage
              alt={coverImage.alt}
              image={coverImage.image.localFile.childImageSharp.gatsbyImageData}
              sx={(theme) => ({
                borderRadius: '25px',
                marginTop: '42px',
                transform: 'translateZ(0)',
                marginLeft: '-24px',
                marginRight: '-24px',
                [theme.breakpoints.down('md')]: {
                  borderRadius: '0',
                },
              })}
            />
          )}
          {blogImageCred && (
            <Typography
              align="center"
              sx={(theme) => ({ display: 'block', fontSize: theme.typography.pxToRem(12), marginTop: '8px' })}
            >
              {blogImageCred}
            </Typography>
          )}
        </Box>
        <Markdown source={blogContent.data.Text} />
        <ShareLinks sx={{ display: 'block', textAlign: 'center', margin: '-50px 0 50px' }} url={location} />
        <Box
          sx={(theme) => ({
            display: 'flex',
            borderTop: `2px solid ${theme.palette.grey[200]}`,
            borderBottom: `2px solid ${theme.palette.grey[200]}`,
            marginBottom: '48px',
            padding: '32px 0',
          })}
        >
          {/* TODO: i18n picture of author */}
          <div>
            <GatsbyImage
              alt={blogAuthor.Name}
              image={blogAuthor.Avatar.localFile.childImageSharp.gatsbyImageData}
              sx={(theme) => ({
                borderRadius: '50%',
                transform: 'translateZ(0)',
                height: '143px',
                width: '143px',
                marginRight: '24px',
                [theme.breakpoints.down('sm')]: {
                  height: '60px',
                  width: '60px',
                },
              })}
            />
          </div>
          <div>
            <Typography gutterBottom variant="h3">
              {intl.formatMessage({ id: 'by' })}&nbsp;{blogAuthor.Name}
            </Typography>
            <Typography variant="body2">{blogAuthor.Shortdescription.data.Shortdescription}</Typography>
          </div>
        </Box>
      </Container>
      <Newsletter themeBase="light" />
      <Container sx={{ textAlign: 'center', marginBottom: '100px' }} narrow>
        {/* ToDo: Move "more" to a more/back-component */}
        <Button variant="contained" color="secondary" to="/">
          {intl.formatMessage({ id: 'backToHomepage' })}
        </Button>
      </Container>
    </Layout>
  );
}

BlogEntry.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
  location: PropTypes.string.isRequired,
};

export function Head({ data }) {
  return (
    <Seo
      title={data.blog.blogTitle}
      description={data.blog.blogDesc}
      image={
        data.blog.ogImageBlog && data.blog.ogImageBlog.localFile.childImageSharp.gatsbyImageData.images.fallback.src
      }
      article
    />
  );
}

export default BlogEntry;

export const query = graphql`
  query GetSingleBlog($Slug: String) {
    blog: strapiBlog(Slug: { eq: $Slug }) {
      blogAuthor: author {
        Avatar {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, formats: PNG)
            }
          }
        }
        Name
        Shortdescription {
          data {
            Shortdescription
          }
        }
      }
      blogContent: Text {
        data {
          Text
        }
      }
      blogDate: Date
      blogDesc: Teaser
      blogId: id
      blogImage: Cover {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
      blogImageAlt: CoverAltText
      blogImageCred: CoverCredentials
      ogImageBlog: Cover {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FIXED, width: 600)
          }
        }
      }
      blogSlug: Slug
      blogSubtitle: Subtitle
      blogTitle: Title
    }
  }
`;
